<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Ônibus" : "Cadastrar Ônibus" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Ônibus</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="10">
              <span class="primary--text">Descrição *</span>
              <v-text-field
                v-model="dados.descricao"
                :rules="fieldRules"
                required
                placeholder="Descrição"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">Prefixo *</span>
              <v-text-field
                v-model="dados.prefixo"
                :rules="fieldRules"
                required
                placeholder="Informe o prefixo"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Tipo de Serviço</span>
              <v-select
                :items="tipos"
                v-model="dados.tipos_servicos"
                item-text="tipo_servico"
                item-value="id"
                multiple
                placeholder="Informe o tipo de serviço"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Modelo de Ônibus</span>
              <v-select
                :items="layouts"
                v-model="dados.id_cad_mod_bu"
                item-text="dados.descricao"
                item-value="dados.id"
                @change="selectPoltronas"
                placeholder="Informe o modelo de ônibus"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Placa *</span>
              <v-text-field
                v-model="dados.placa"
                :rules="fieldRules"
                required
                v-mask="'LLL-9999'"
                class="textUppercase mt-n1"
                placeholder=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Quantidade Poltronas</span>
              <v-text-field
                v-model="dados.qtd_poltronas"
                :rules="fieldRules"
                required
                disabled
                placeholder=""
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        prefixo: "",
        placa: "",
        qtd_poltronas: "",
        descricao: "",
        tipos_servicos: [],
        id_cad_mod_bu: 0,
      },
      tipos: [],
      layouts: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    this.loader("tiposservico");
    this.loader("layouts");
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/bus/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.dados.tipos_servicos = JSON.parse(this.dados.tipos_servicos);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    loader(table) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/` + table)
        .then((r) => {
          table == "tiposservico"
            ? (this.tipos = r.data)
            : (this.layouts = r.data);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    selectPoltronas(selectObj) {
      this.$http
        .get(`/layouts/${selectObj}`)
        .then((r) => {
          this.dados.qtd_poltronas = r.data[0].qtd_poltronas;
        })
        .catch((e) => {
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.dados.descricao = this.dados.descricao
        ? this.dados.descricao.toUpperCase()
        : this.dados.descricao;
      this.dados.placa = this.dados.placa
        ? this.dados.placa.toUpperCase()
        : this.dados.placa;
      this.$store.dispatch("activeLoader", true);
      this.dados.tipos_servicos = JSON.stringify(this.dados.tipos_servicos);
      if (this.id) {
        this.$http
          .post(`/bus/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros-operacionais/onibus/gerenciar"); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/bus/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros-operacionais/onibus/gerenciar");
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
